'use client'

import { m, AnimatePresence } from "framer-motion"

export default function ScrollDown() {
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: { pathLength: 1, opacity: 1 }
  }

  return (
    <AnimatePresence>
      <div className='h-20 w-20 animate-bounce'>
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 148 139' >
          <m.path
            initial={draw.hidden}
            animate={draw.visible}
            transition={{ delay: 1.2, duration: 1.2 }}
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='1.5'
            strokeWidth='6'
            d='M3.169 112.804c3.917 7.098 9.398 16.54 14.407 22.794m0 0c.577.721 4.466-5.015 4.622-5.295 3.448-6.128 5.988-12.52 8.437-19.09m-13.059 24.385C5.274 93.297 21.98 52.008 50.645 39.174c2.84-1.272 5.934-2.654 9.137-3.754m0 0c5.102-1.752 10.48-2.79 15.56-1.538 4.688 1.157 10.308 4.638 11.665 9.505 2.535 9.09-7.685 16.315-16.145 12.977-7.435-2.934-13.067-13.29-11.08-20.944Zm0 0c.042-.161.088-.321.136-.48 11.767-38.363 58.942-39 84.515-18.403'
          />
        </svg>
      </div>
    </AnimatePresence>
  )
}
