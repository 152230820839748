import { useRef } from 'react'
import { wrap } from '@motionone/utils'
import { KeyTextField } from '@prismicio/types'
import { m, useAnimationFrame, useMotionValue, useScroll, useSpring, useTransform, useVelocity } from 'framer-motion'

interface MarqueeProps {
  children: KeyTextField
  baseVelocity: number
}

export default function MarqueeText({ children, baseVelocity = 100 }: MarqueeProps) {
  const baseX = useMotionValue(0)
  const { scrollY } = useScroll()
  const scrollVelocity = useVelocity(scrollY)
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 300,
  })
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  })

  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`)

  const directionFactor = useRef<number>(1)
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000)

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get()

    baseX.set(baseX.get() + moveBy)
  })

  return (
    <div className='m-0 flex w-full overflow-hidden whitespace-nowrap py-8 tracking-tight'>
      <m.div
        style={{ x }}
        className='flex flex-nowrap whitespace-nowrap font-serif text-7xl font-semibold text-wave-foreground md:text-9xl'
      >
        <span className='mr-6 block'>{children}</span>
        <span className='mr-6 block'>{children}</span>
        <span className='mr-6 block'>{children}</span>
        <span className='mr-6 block'>{children}</span>
      </m.div>
    </div>
  )
}
