'use client'

import { m } from 'framer-motion'

type Props = {
  text: string
  level: number
  className: string
}

const wordVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.07,
      ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.75,
    },
  },
}

const characterVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const sentenceVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.12,
      ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.75,
    },
  },
}

export default function AnimatedText({ text, level, className }: Props) {
  switch (level) {
    case 1:
      return (
        <m.h1
          variants={sentenceVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className={`${className} overflow-hidden leading-none tracking-tight`}
        >
          {text.split(' ').map((word, index) => (
            <m.span aria-hidden key={index} className='mr-4 inline-block' variants={wordVariants}>
              {word.split('').map((char, index) => (
                <m.span key={index} variants={characterVariants}>
                  {char}
                </m.span>
              ))}
            </m.span>
          ))}
        </m.h1>
      )
    case 2:
      return (
        <m.h2
          variants={sentenceVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className={`${className} overflow-hidden leading-none tracking-tight`}
        >
          {text.split(' ').map((word, index) => (
            <m.span aria-hidden key={index} className='mr-3 inline-block whitespace-nowrap' variants={wordVariants}>
              {word.split('').map((char, index) => (
                <m.span key={index} variants={characterVariants}>
                  {char}
                </m.span>
              ))}
            </m.span>
          ))}
        </m.h2>
      )
    case 3:
      return (
        <m.h3
          variants={wordVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className={`${className} overflow-hidden font-bold leading-none tracking-tight`}
        >
          {text.split('').map((char, index) => (
            <m.span key={char + '-' + index} variants={characterVariants}>
              {char}
            </m.span>
          ))}
        </m.h3>
      )
    default:
      return (
        <m.h3
          variants={wordVariants}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className={`${className} overflow-hidden font-bold leading-none tracking-tight`}
        >
          {text.split('').map((char, index) => (
            <m.span key={char + '-' + index} variants={characterVariants}>
              {char === ' ' ? '\u0020' : char}
            </m.span>
          ))}
        </m.h3>
      )
  }
}
