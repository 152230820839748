'use client'

import { m } from 'framer-motion'

import { buttonTransition, linkVariants } from '@/lib/motion'

type Props = {
  children: React.ReactNode
}

export default function AnimatedButton({ children }: Props) {
  return (
    <m.div variants={linkVariants} whileTap='whileTap' whileHover='whileHover' transition={buttonTransition}>
      {children}
    </m.div>
  )
}
