'use client'

import { useRef } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'

import { cn } from '@/lib/utils'

interface WaveProps {
  className: string
  variant: 'top1' | 'top2' | 'top3' | 'bottom1' | 'bottom2'
}

export default function Wave({ className, variant = 'top1' }: WaveProps) {
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', 'end start'],
  })

  const scaleY = useTransform(scrollYProgress, [0, 0.9], [1, 2.4])

  const variants = {
    top1: 'm0 9.24 35.2 15.282c35.2 15.282 107.2 45.845 177.6 106.971 72 61.127 142.4 152.817 214.4 160.458 70.4 7.641 142.4-68.768 212.8-129.894 70.4-61.127 142.4-106.972 212.8-68.768 72 38.204 142.4 160.458 214.4 160.458 70.4 0 142.4-122.254 212.8-191.021 70.4-68.767 142.4-84.05 212.8-30.563 72 53.486 142.4 175.739 214.4 198.661 70.4 22.923 142.4-53.486 177.6-91.69l35.2-38.204V376H0V9.24Z',
    top2: 'm0 143.445 35.2-31.007C70.4 81.431 142.4 19.416 212.8 3.913c72-15.504 142.4 15.503 214.4 85.27 70.4 69.766 142.4 178.291 212.8 193.795 70.4 15.504 142.4-62.014 212.8-131.781 72-69.766 142.4-131.78 214.4-108.525 70.4 23.255 142.4 131.781 212.8 139.532 70.4 7.752 142.4-85.27 212.8-100.773 72-15.504 142.4 46.511 214.4 100.773 70.4 54.263 142.4 100.774 177.6 124.03l35.2 23.255V376H0V143.445Z',
    top3: 'm0 66.253 35.2 51.625c35.2 51.624 107.2 154.873 177.6 172.081 72 17.208 142.4-51.624 214.4-77.437 70.4-25.812 142.4-8.604 212.8 0 70.4 8.605 142.4 8.605 212.8-25.812 72-34.416 142.4-103.249 214.4-146.27 70.4-43.02 142.4-60.228 212.8-8.603 70.4 51.624 142.4 172.081 212.8 232.31 72 60.229 142.4 60.229 214.4 8.604 70.4-51.624 142.4-154.873 177.6-206.498L1920 14.63V376H0V66.253Z',
    bottom1:
      'm0 376 24-35c25.6-35 75.2-105 123.2-155 49.6-50 99.2-80 148.8-70 48 10 97.6 60 147.2 75 49.6 15 97.6-5 147.2-20 49.6-15 99.2-25 148.8 0 48 25 97.6 85 147.2 115 49.6 30 97.6 30 147.2 5 49.6-25 99.2-75 147.2-105 49.6-30 99.2-40 148.8-20 49.6 20 97.6 70 147.2 70 49.6 0 99.2-50 147.2-50 49.6 0 99.2 50 148.8 85 48 35 97.6 55 123.2 65l24 10V0H443.2A55362690964117 55362690964117 0 0 1 24 0H0v376Z',
    bottom2:
      'm1920 322.286-35.2-35.81c-35.2-35.809-107.2-107.428-177.6-134.286-72-26.857-142.4-8.952-214.4 35.81-70.4 44.762-142.4 116.381-212.8 89.524s-142.4-152.191-212.8-134.286c-72 17.905-142.4 179.048-214.4 205.905C782.4 376 710.4 268.571 640 232.762c-70.4-35.81-142.4 0-212.8-17.905-72-17.905-142.4-89.524-214.4-71.619-70.4 17.905-142.4 125.333-177.6 179.048L0 376V0h1920v322.286Z',
    bottom3:
      'm0 376 24-35c25.6-35 75.2-105 123.2-155 49.6-50 99.2-80 148.8-70 48 10 97.6 60 147.2 75 49.6 15 97.6-5 147.2-20 49.6-15 99.2-25 148.8 0 48 25 97.6 85 147.2 115 49.6 30 97.6 30 147.2 5 49.6-25 99.2-75 147.2-105 49.6-30 99.2-40 148.8-20 49.6 20 97.6 70 147.2 70 49.6 0 99.2-50 147.2-50 49.6 0 99.2 50 148.8 85 48 35 97.6 55 123.2 65l24 10V0H0v376Z',
  }

  return (
    <div ref={container} className='h-full w-full overflow-x-hidden'>
      <m.svg
        viewBox='0 0 1920 376'
        className={cn('absolute w-full', className)}
        style={{ scaleY }}
        preserveAspectRatio='none'
      >
        <path fill='currentColor' d={variants[variant]} />
      </m.svg>
    </div>
  )
}
