'use client'

import { m } from 'framer-motion'
import { useTheme } from 'next-themes'

import { cn } from '@/lib/utils'

import { buttonTransition, buttonVariants } from '../lib/motion'
import { Icons } from './icons'
import { buttonVariants as buttonStyle } from './ui/button'

export default function ToggleTheme() {
  const { theme, setTheme } = useTheme()

  return (
    <m.div
      onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
      variants={buttonVariants}
      whileHover='whileHover'
      whileTap='whileTap'
      initial='initial'
      animate='animate'
      transition={buttonTransition}
      className={cn(
        buttonStyle({
          variant: 'ghost',
          size: 'icon',
        }),
        'cursor-pointer',
      )}
    >
      <Icons.moon className='rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0' aria-hidden />
      <Icons.sun className='absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100' aria-hidden />
    </m.div>
  )
}
