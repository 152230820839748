import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/node_modules/@prismicio/react/dist/PrismicToolbar.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/layout/footer.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/layout/navigation.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/scroll-down.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/show-after-first-render.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/ui/animated-button.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/ui/animated-text.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/ui/fade-in.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/ui/parallax.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/components/ui/wave.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/bibis-website/src/slices/Menu/index.tsx")