import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function checkWorkHours() {
  const now = new Date()
  return now.getHours() >= 9 && now.getHours() <= 18
}
