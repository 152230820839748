'use client'

import { m } from 'framer-motion'

type Props = {
  children: React.ReactNode
}

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.17, 0.67, 0.83, 0.67],
      duration: 0.6,
    },
  },
}

export default function FadeIn({ children }: Props) {
  return (
    <m.div variants={variants} initial='hidden' whileInView='visible'>
      {children}
    </m.div>
  )
}
