'use client'

import { m } from 'framer-motion'

import { checkWorkHours, cn } from '@/lib/utils'

import { NavigationDocument } from '../../../prismicio-types'
import { buttonTransition, buttonVariants } from '../../lib/motion'
import { Icons } from '../icons'
import LocaleSwitcher from '../locale-switcher'
import ToggleTheme from '../toggle-theme'
import { buttonVariants as buttonStyles } from '../ui/button'

export default function Navigation({ navigation }: { navigation: NavigationDocument }) {
  const openCurrently = checkWorkHours()

  return (
    <header className='fixed bottom-2 left-0 z-50 h-fit w-full sm:top-0'>
      <nav className='mx-auto p-4 font-semibold text-foreground'>
        <div className={cn('flex content-center items-end justify-between ', { 'justify-end': !openCurrently })}>
          {openCurrently && (
            <m.div
              variants={buttonVariants}
              whileHover='whileHover'
              whileTap='whileTap'
              initial='initial'
              animate='animate'
              transition={buttonTransition}
              className={cn(
                buttonStyles({
                  variant: 'ghost',
                }),
              )}
            >
              <a href={`tel:+${navigation.data.phoneNumber}`} className='flex items-center gap-2' aria-label='Phone number'>
                <Icons.phoneCall />
                <span className='hidden sm:block'>{navigation.data.phoneLabel}</span>
              </a>
            </m.div>
          )}
          <div className='flex flex-col gap-2 md:flex-row'>
            <ToggleTheme />
            <LocaleSwitcher altLanguage={navigation.alternate_languages} />
          </div>
        </div>
      </nav>
    </header>
  )
}
