'use client'

import { useLayoutEffect, useRef, useState } from 'react'
import { m, useReducedMotion, useScroll, useTransform } from 'framer-motion'

type ParallaxProps = {
  children: React.ReactNode
  offset?: number
  rotation?: number
}

export default function Parallax({ children, offset = 200, rotation = 5 }: ParallaxProps) {
  const prefersReducedMotion = useReducedMotion()
  const [elementTop, setElementTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  const { scrollY } = useScroll()

  const initial = elementTop - clientHeight
  const final = elementTop + offset

  const y = useTransform(scrollY, [initial, final], [offset, -offset])
  const rotateX = useTransform(scrollY, [initial, final], [rotation, -rotation])

  useLayoutEffect(() => {
    const element = ref.current
    const onResize = () => {
      element && setElementTop(element.getBoundingClientRect().top + window.scrollY)
      setClientHeight(window.innerHeight)
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [ref])

  if (prefersReducedMotion) {
    return <>{children}</>
  }

  return (
    <m.div ref={ref} style={{ y, rotate: rotateX }}>
      {children}
    </m.div>
  )
}
