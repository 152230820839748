import type { LucideIcon } from 'lucide-react'
import { PiPhoneCallFill } from 'react-icons/pi'
import { TbMoonFilled, TbSunFilled } from 'react-icons/tb'
import { FaFacebook, FaInstagram } from 'react-icons/fa'


export type Icon = LucideIcon

export const Icons = {
  sun: TbSunFilled,
  moon: TbMoonFilled,
  phoneCall: PiPhoneCallFill,
  instagram: FaInstagram,
  facebook: FaFacebook,
}
