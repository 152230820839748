'use client'

import { PrismicNextLink } from '@prismicio/next'
import { AlternateLanguage } from '@prismicio/types'
import { m } from 'framer-motion'

import { cn } from '@/lib/utils'

import { buttonTransition, buttonVariants } from '../lib/motion'
import { buttonVariants as buttonStyles } from './ui/button'

interface LocaleSwitcherProps {
  altLanguage: AlternateLanguage<'navigation'>[]
}

export default function LocaleSwitcher({ altLanguage }: LocaleSwitcherProps) {
  return (
    <ul>
      {altLanguage.map((locale) => (
        <m.li
          variants={buttonVariants}
          whileHover='whileHover'
          whileTap='whileTap'
          initial='initial'
          animate='animate'
          transition={buttonTransition}
          key={locale.lang}
          className={cn(
            buttonStyles({
              variant: 'ghost',
              size: 'icon',
            }),
          )}
        >
          <PrismicNextLink
            scroll={false}
            href={locale.lang === 'sr-rs' ? '/' : '/en-gb'}
            locale={locale.lang}
            aria-label={`Change language to ${locale.lang}`}
          >
            {locale.lang.substring(0, 2).toUpperCase()}
          </PrismicNextLink>
        </m.li>
      ))}
    </ul>
  )
}
