'use client'

import { PrismicNextImage, PrismicNextLink } from '@prismicio/next'
import { FaFacebook, FaInstagram } from 'react-icons/fa'

import AnimatedButton from '@/components/ui/animated-button'
import MarqueeText from '@/components/ui/marquee-text'
import Wave from '@/components/ui/wave'

import { FooterDocument } from '../../../prismicio-types'

export default function Footer({ footer }: { footer: FooterDocument }) {
  const iconProperties = 'h-10 w-10'

  const icons = {
    facebook: <FaFacebook className={iconProperties} />,
    instagram: <FaInstagram className={iconProperties} />,
  }

  return (
    <footer className='relative mt-48 bg-wave'>
      <Wave className='-top-24 h-24  origin-bottom text-wave md:-top-36 md:h-36' variant='top3' />
      <MarqueeText baseVelocity={4}>{footer.data.marqueeText}</MarqueeText>
      <div className='mx-auto my-8 grid w-full max-w-screen-lg items-center gap-12 overflow-hidden px-6 text-xl text-wave-foreground md:grid-cols-2 md:gap-24 md:px-10'>
        <PrismicNextImage field={footer.data.image} className='w-full animate-spin-slow rounded-t-full' />
        <div className='grid gap-16 font-medium'>
          <address className='grid justify-center gap-4 text-center not-italic md:justify-start md:text-left'>
            <AnimatedButton>
              <a href={`mailto:${footer.data.eMail}`} target='_blank' className='w-fit' aria-label='Email'>
                {footer.data.eMail}
              </a>
            </AnimatedButton>
            <AnimatedButton>
              <a href={`tel:${footer.data.phoneNumber?.replace(/^a-zA-Z0-9]/g, '')}`} target='_blank' className='w-fit' aria-label='Phone number'>
                {footer.data.phoneNumber}
              </a>
            </AnimatedButton>
          </address>
          <div>
            {footer.data.workHours.map((hour) => (
              <div key={hour.label} className='mb-4 text-center md:text-left'>
                <strong>{hour.label}:</strong> {hour.hours}
              </div>
            ))}
          </div>
          <div className='flex justify-center gap-4 md:justify-start'>
            {footer.data.socials.map((social) => (
              <AnimatedButton key={social.label}>
                <PrismicNextLink field={social.link} target='_blank' aria-label={social.label as string}>
                  {icons[social.label as keyof typeof icons]}
                </PrismicNextLink>
              </AnimatedButton>
            ))}
          </div>
        </div>
      </div>
      <div className='p-4 sm:p-6 xl:p-8'>
        <p className='text-center text-sm text-gray-500 dark:text-gray-400'>&copy; {footer.data.copyright}</p>
      </div>
    </footer>
  )
}
