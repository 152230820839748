export const buttonVariants = {
  initial: {
    backgroundColor: 'transparent',
  },
  animate: {
    backgroundColor: 'hsl(var(--background))',
  },
  whileHover: {
    scale: 1.1,
    backgroundColor: 'hsl(var(--background-hover))',
  },
  whileTap: {
    scale: 0.9,
  },
}

export const linkVariants = {
  whileHover: {
    scale: 1.1,
  },
  whileTap: {
    scale: 0.9,
  },
}

export const buttonTransition = {
  type: 'spring',
  stiffness: 450,
  damping: 8,
}

export const springTransition = {
  type: 'spring',
  stiffness: 200,
  damping: 10,
}

export const sentenceVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.1,
    },
  },
}

export const letterVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
}
