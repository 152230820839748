'use client'

import { useEffect, useState } from 'react'

import { cn } from '@/lib/utils'

interface ShowAfterFirstRenderProps {
  children: React.ReactNode
}

export default function ShowAfterFirstRender({ children }: ShowAfterFirstRenderProps) {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setShow(true)
  }, [])

  return <div className={cn('opacity-0 transition-opacity duration-1000', show && 'opacity-100')}>{children}</div>
}
