'use client'

import { Content } from '@prismicio/client'
import { SliceComponentProps } from '@prismicio/react'
import { PrismicNextImage } from '@prismicio/next'
import { cn } from '@/lib/utils'
import AnimatedText from '@/components/ui/animated-text'
import FadeIn from '@/components/ui/fade-in'

/**
 * Props for `Menu`.
 */
export type MenuProps = SliceComponentProps<Content.MenuSlice>

/**
 * Component for "Menu" Slices.
 */
const Menu = ({ slice }: MenuProps): JSX.Element => {
  return (
    <section
      data-slice-variation={slice.variation}
      className='mx-auto px-4 sm:max-w-xl md:max-w-full md:px-24 lg:mb-28 lg:max-w-screen-lg lg:px-8'
    >
      <div
        className={cn('text-foreground flex flex-col lg:flex-row lg:justify-between lg:gap-24', {
          'lg:flex-row-reverse': slice.variation === 'right',
        })}
      >
        <div className='flex items-start lg:w-1/2 '>
          <div className='w-full lg:py-[30vh]'>
            {slice.primary.title && (
              <AnimatedText text={slice.primary.title} level={3} className='mb-32 font-serif text-6xl' />
            )}
            <ol>
              {slice.items.map((item) => (
                <li className='mb-16' key={item.name}>
                  <FadeIn >
                    <h4 className='mb-2	text-3xl font-semibold leading-none tracking-tight'>{item.name}</h4>
                    <p>{item.description}</p>
                  </FadeIn>
                </li>
              ))}
            </ol>
          </div>
        </div>
        <div className='sticky top-0 hidden h-screen items-center lg:flex lg:w-1/2'>
          <PrismicNextImage field={slice.primary.media} className='w-auto' />
        </div>
      </div>
    </section>
  )
}

export default Menu
